.footer {
  background-color: #006462;
  color: #fff;
  padding: 40px 20px;
  ; /* Added padding for smaller screens */
  /* position: absolute;
  width: 100%;
  bottom: 0; */
}

.inner {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.footer-left,
.footer-center,
.footer-right {
  flex: 1;
  margin: 10px;
}

.footer-left h3,
.footer-center h3 {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.footer-left p,
.footer-center p {
  margin: 0;
  display: flex; /* Ensure items are flex */
  align-items: center; /* Center align vertically */
}

.footer-right h4 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.footer-right ul {
  list-style-type: none;
  padding: 30px;
}

.footer-right ul li {
  margin-bottom: 8px;
}

.footer-right ul li a {
  color: #f3ca52;
  text-decoration: underline;
  font-size: 1.2rem;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9rem;
}

.footer-bottom p {
  margin: 0;
}

.footer-center a {
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
}

.footer-center a:hover {
  text-decoration: underline;
}

.footer img {
  width: 40px; /* Adjust the width as needed */
  vertical-align: middle;
  margin-right: 5px;
}

.yellow-line {
  width: 80%;
  height: 2px;
  background-color: #f3ca52;
  margin: 20px auto; /* Center the line and add spacing */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .inner {
    flex-direction: column;
    align-items: center;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    text-align: center;
  }

  .footer img {
    width: 30px; /* Smaller images for smaller screens */
  }
}
